import gql from "graphql-tag";

export const GET_LDP_JOURNEY = gql`
   query GetLdpJourney($leadDataId: ID!, $verticalId: ID!, $subVerticalId: ID!, $fetchEDW: Boolean) {
    LDPIngestQueryGroup {
      GetLdpJourney(LeadDataId: $leadDataId, VerticalId: $verticalId, SubVerticalId: $subVerticalId, FetchEDW:  $fetchEDW){
        BuyerName
        DispositionCategory
        DispositionSubCategory
        IsPing
        LeadDataId
        SignupId
        PingQueueId
        PostQueueId
        ResponseDispositionId
        ResponseDispositionName
        SubVerticalId
        Summary
        TransmitDate
        VerticalId
      }
    }
  }
`;

export const GET_LDP_JOURNEY_REQ_RESP = gql`
   query GetLdpJourneyRequestResponse($verticalId:ID!, $subVerticalId:ID!, $pingQueueId:ID!, $postQueueId:ID!, $isPing:Boolean!, $fetchEdw: Boolean) {
    LDPIngestQueryGroup {
      GetLdpProcResponseRequest(VerticalIdOps: $verticalId, SubVerticalIdOps: $subVerticalId, PingQueueId: $pingQueueId, PostQueueId: $postQueueId, IsPing: $isPing, FetchEDW: $fetchEdw){
        ReceiveDate
        TransmitDate
        RequestPayload
        ResponsePayload
        ErrorMessage
        SignupId
      }
    }
  }
`;
