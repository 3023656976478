import { useLazyQuery } from "@apollo/react-hooks";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  GET_LDP_JOURNEY,
  GET_LDP_JOURNEY_REQ_RESP,
} from "../../common/models/ldpJourney";
import { GetLdpJourney } from "../../common/models/types/GetLdpJourney";
import {
  GetLdpJourneyRequestResponse,
  GetLdpJourneyRequestResponse_LDPIngestQueryGroup_GetLdpProcResponseRequest,
} from "../../common/models/types/GetLdpJourneyRequestResponse";
import { dateToPreferredTimezone } from "../../common/utils/date";
import {
  LDPUIDataTable,
  PrettyPayload,
  queryLoad,
  StatusIcon,
} from "../../components";
import {
  numberColumnFilter,
  stringColumnFilter,
} from "../../components/customMuiDatatableFilter";
import { da } from "date-fns/locale";

interface LdpJourneyProps {
  LeadDataId: number | string;
  VerticalId: number | string;
  SubVerticalId: number | string;
  FetchEDW?: boolean;
}

interface LdpProcResponseRequestArgs {
  VerticalId: number | string;
  SubVerticalId: number | string;
  PingQueueId: number | string;
  PostQueueId: number | string;
  IsPing: boolean;
}

export const LdpJourneyDetails = ({
  LeadDataId,
  VerticalId,
  SubVerticalId,
  FetchEDW,
}: LdpJourneyProps) => {
  const [
    getLdpJourney,
    { data, error, loading, called, refetch },
  ] = useLazyQuery<GetLdpJourney>(GET_LDP_JOURNEY);
  const [
    getDetails,
    { data: detailsData, error: detailsError, loading: detailsLoading },
  ] = useLazyQuery<GetLdpJourneyRequestResponse>(GET_LDP_JOURNEY_REQ_RESP, {
    fetchPolicy: "network-only",
  });

  const [highlightedRow, setHighlightedRow] = useState<number[]>([]);
  const [detailsOn, setDetailsOn] = useState<LdpProcResponseRequestArgs | null>(
    null
  );
  const [details, setDetails] = useState<
    | GetLdpJourneyRequestResponse_LDPIngestQueryGroup_GetLdpProcResponseRequest
    | undefined
    | null
  >();

  useEffect(() => {
    if (!loading && !called) {
      getLdpJourney({
        variables: {
          leadDataId: LeadDataId,
          verticalId: VerticalId,
          subVerticalId: SubVerticalId,
          fetchEDW: !!FetchEDW,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (!!detailsOn) {
      getDetails({
        variables: {
          verticalId: detailsOn.VerticalId,
          subVerticalId: detailsOn.SubVerticalId,
          pingQueueId: detailsOn.PingQueueId,
          postQueueId: detailsOn.PostQueueId,
          isPing: detailsOn.IsPing,
          fetchEdw: !!FetchEDW,
        },
      });
    }
  }, [detailsOn]);

  useEffect(() => {
    if (!!detailsData) {
      setDetails(
        detailsData?.LDPIngestQueryGroup?.GetLdpProcResponseRequest[0]
      );
    } else if (detailsError) {
      setDetails(null);
    }
  }, [detailsData, detailsError]);

  useEffect(() => {
    if (
      data &&
      data.LDPIngestQueryGroup?.GetLdpJourney?.length === 0 &&
      !FetchEDW
    ) {
      // retry on EDW mode
      getLdpJourney({
        variables: {
          leadDataId: LeadDataId,
          verticalId: VerticalId,
          subVerticalId: SubVerticalId,
          fetchEDW: true,
        },
      });
    }
  }, [data]);

  const showDetails = (detailsParam: LdpProcResponseRequestArgs) => {
    if (!detailsOn) {
      //console.log("load new", detailsParam);
      setDetailsOn(detailsParam);
    } else {
      if (JSON.stringify(detailsOn) === JSON.stringify(detailsParam)) {
        //console.log("close ", detailsParam);
        setDetailsOn(null); //clicked same row, toggles it off
        setHighlightedRow([]);
      } else {
        //console.log("change ", detailsParam);
        setDetailsOn(detailsParam);
      }
    }

    setDetails(null);
  };

  const getTableOptions = (table: string) => {
    const TableOptions: MUIDataTableOptions = {
      filterType: "checkbox",
      selectableRows: "none",
      responsive: "vertical",
      sortOrder: {
        name:
          table === "BuyerFailures"
            ? "ResponseDispositionName"
            : "TransmitDate",
        direction: "desc",
      },
      rowsPerPage: 100,
      rowsPerPageOptions: [25, 50, 100],
      fixedHeader: true,
      fixedSelectColumn: true,
      // rowsSelected: highlightedRow,
      // selectableRowsHeader: false,
      // selectableRowsHideCheckboxes: true,
      onRowClick: (row: string[], { dataIndex, rowIndex }) => {
        const dataSet =
          table === "Success"
            ? successData
            : table === "BuyerFailures"
            ? buyerFailuresData
            : table === "FilterFailed"
            ? presendFailuresData1
            : presendFailuresData2;

        const selected = dataSet[dataIndex];

        if(selected){
          const {
            PingQueueId,
            PostQueueId,
            VerticalId,
            SubVerticalId,
            IsPing = false,
          } = selected;
  
          showDetails({
            VerticalId,
            SubVerticalId,
            PingQueueId,
            PostQueueId,
            IsPing: !!IsPing,
          });
  
          setHighlightedRow([rowIndex]);
        }
        
      },
    };
    return TableOptions;
  };

  const getTableColumns = (table: string) => {
    const LdpJourneyColumns = [
      {
        name: "BuyerName",
        label: "Buyer Name",
        options: {
          viewColumns: true,
          filter: true,
          ...stringColumnFilter,
        },
      },
      {
        name: "DispositionCategory",
        label: "Disposition Category",
        options: {
          viewColumns: true,
          filter: true,
          ...stringColumnFilter,
        },
      },
      {
        name:
          table === "BuyerFailures"
            ? "ResponseDispositionName"
            : "DispositionSubCategory",
        label:
          table === "BuyerFailures"
            ? "Disposition Name"
            : "Disposition Sub Category",
        options: {
          viewColumns: true,
          filter: true,
          ...stringColumnFilter,
          customBodyRender: (value: any, tableMeta: any) => {
            return value;
          },
        },
      },
      {
        name: "Summary",
        label: "Summary",
        options: {
          viewColumns: true,
          filter: true,
          ...stringColumnFilter,
          customBodyRender: (value: any, tableMeta: any) => {
            const [
              Col1,
              Col2,
              Col3,
              Col4,
              Col5,
              Col6,
              Col7,
              ResponseDispositionName,
              PingQueueId,
              PostQueueId,
              VerticalId,
              SubVerticalId,
              IsPing,
            ] = tableMeta.rowData;

            return (
              <Link
                href="#"
                onClick={() => {
                  setHighlightedRow([tableMeta.rowIndex]);
                }}
                style={{ textDecoration: "none" }}
              >
                {value}
                {!!ResponseDispositionName
                  ? ` ${ResponseDispositionName}`
                  : ` ${Col3}`}
              </Link>
            );
          },
        },
      },
      {
        name: "TransmitDate",
        label: "Date",
        options: {
          viewColumns: true,
          filter: false,
          customBodyRender: (value: any, tableMeta: any) =>
            dateToPreferredTimezone(value),
        },
      },
      {
        name: "SignupId",
        label: "Signup ID",
        options: {
          viewColumns: true,
          filter: true,
          ...numberColumnFilter,
        },
      },
      {
        name: "LeadDataId",
        label: "Lead Data ID",
        options: {
          viewColumns: true,
          filter: true,
          ...numberColumnFilter,
        },
      },
      {
        name: "IsPing",
        label: "Is Ping",
        options: {
          viewColumns: true,
          filter: false,
          customBodyRender: (value: any, tableMeta: any) => {
            return <StatusIcon value={value === 'true' || value === true} />;
          },
        },
      },
      {
        name: table !== "BuyerFailures" ? "ResponseDispositionName" : "",
        label: table !== "BuyerFailures" ? "Disposition Name" : "",
        options: {
          viewColumns: false,
          filter: false,
          display: false,
        },
      },
      {
        name: "PingQueueId",
        label: "PingQueueId",
        options: {
          viewColumns: false,
          filter: false,
          display: false,
        },
      },
      {
        name: "PostQueueId",
        label: "PostQueueId",
        options: {
          viewColumns: false,
          filter: false,
          display: false,
        },
      },
      {
        name: "VerticalId",
        label: "VerticalId",
        options: {
          viewColumns: false,
          filter: false,
          display: false,
        },
      },
      {
        name: "SubVerticalId",
        label: "SubVerticalId",
        options: {
          viewColumns: false,
          filter: false,
          display: false,
        },
      },
      {
        name: "IsPing",
        label: "IsPing",
        options: {
          viewColumns: false,
          filter: false,
          display: false,
        },
      },
    ];
    return LdpJourneyColumns;
  };

  const columnSuccess = getTableColumns("Success");
  const columnBuyerFailures = getTableColumns("BuyerFailures");
  const columnFilterFailed = getTableColumns("FilterFailed");
  const columnCapacityFailed = getTableColumns("CapacityFailed");

  const [expandedTab, setExpandedTab] = useState<any>({
    tab1: false,
    tab2: false,
    tab3: false,
  });

  const successData = useMemo(() => {
    return (
      data?.LDPIngestQueryGroup?.GetLdpJourney?.filter(
        (journey) =>
          (journey?.DispositionCategory === "Buyer Response" &&
            journey.DispositionSubCategory === "Success") ||
          (journey?.DispositionCategory === null &&
            journey.DispositionSubCategory === null)
      ) || []
    );
  }, [data]);

  const buyerFailuresData = useMemo(() => {
    return (
      data?.LDPIngestQueryGroup?.GetLdpJourney?.filter(
        (journey) =>
          journey?.DispositionCategory === "Buyer Response" &&
          journey.DispositionSubCategory === null
      ) || []
    );
  }, [data]);

  const presendFailuresData1 = useMemo(() => {
    return (
      data?.LDPIngestQueryGroup?.GetLdpJourney?.filter(
        (journey) =>
          journey?.DispositionCategory === "Filter" &&
          (journey.DispositionSubCategory === "Contract" ||
            journey.DispositionSubCategory === "Buyer")
      ) || []
    );
  }, [data]);

  const presendFailuresData2 = useMemo(() => {
    return (
      data?.LDPIngestQueryGroup?.GetLdpJourney?.filter(
        (journey) =>
          journey?.DispositionCategory === "Capacity" &&
          journey.DispositionSubCategory === "Buyer Monetary"
      ) || []
    );
  }, [data]);

  const handleAccordionChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpandedTab({ ...expandedTab, [panel]: isExpanded });
  };

  return (
    <Grid
      container
      spacing={3}
      style={{
        padding: "7px 15px",
      }}
    >
      <Grid item xs={!!detailsOn ? 8 : 12}>
        {queryLoad([!!loading, !!detailsLoading], [error]) || (
          <>
            <Accordion
              expanded={expandedTab.tab1}
              onChange={handleAccordionChange("tab1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="tab1-content"
                id="tab1-header"
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "teal",
                  }}
                >
                  Success
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <LDPUIDataTable
                      ldpTableId={`ldp-journey-${LeadDataId}-${VerticalId}-${SubVerticalId}-success`}
                      restoreFilters={true}
                      title=""
                      data={successData}
                      columns={columnSuccess}
                      options={getTableOptions("Success")}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandedTab.tab2}
              onChange={handleAccordionChange("tab2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="tab2-content"
                id="tab2-header"
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "teal",
                  }}
                >
                  Buyer Failures
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <LDPUIDataTable
                      ldpTableId={`ldp-journey-${LeadDataId}-${VerticalId}-${SubVerticalId}-buyerfailure`}
                      restoreFilters={true}
                      title=""
                      data={buyerFailuresData}
                      columns={columnBuyerFailures}
                      options={getTableOptions("BuyerFailures")}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandedTab.tab3}
              onChange={handleAccordionChange("tab3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="tab3-content"
                id="tab3-header"
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "teal",
                  }}
                >
                  PreSend Failures
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <LDPUIDataTable
                      ldpTableId={`ldp-journey-${LeadDataId}-${VerticalId}-${SubVerticalId}-filterfailed`}
                      restoreFilters={true}
                      title="Filter Failed"
                      data={presendFailuresData1}
                      columns={columnFilterFailed}
                      options={getTableOptions("FilterFailed")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LDPUIDataTable
                      ldpTableId={`ldp-journey-${LeadDataId}-${VerticalId}-${SubVerticalId}-capacityfailed`}
                      restoreFilters={true}
                      title="Capacity Failed"
                      data={presendFailuresData2}
                      columns={columnCapacityFailed}
                      options={getTableOptions("CapacityFailed")}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </Grid>
      {!!detailsOn ? (
        <Grid item xs={4}>
          {!!details ? (
            <Grid container>
              <Grid item xs={12}>
                <S.DetailsHeader>
                  <S.DetailsHeaderItem>
                    Transmit Date:{" "}
                    {dateToPreferredTimezone(details.TransmitDate)}
                  </S.DetailsHeaderItem>
                  <S.DetailsHeaderItem>
                    Received Date:{" "}
                    {dateToPreferredTimezone(details.ReceiveDate)}
                  </S.DetailsHeaderItem>
                </S.DetailsHeader>
              </Grid>
              <Grid item xs={12}>
                {!!details && !!details.RequestPayload ? (
                  <PrettyPayload value={details?.RequestPayload} />
                ) : null}
              </Grid>
              <Grid item xs={12}>
                {" "}
              </Grid>
              <Grid item xs={12}>
                Signup ID: {details.SignupId}
              </Grid>
              <Grid item xs={12}>
                {" "}
              </Grid>
              <Grid item xs={12}>
                {!!details && !!details.ResponsePayload ? (
                  <PrettyPayload value={details?.ResponsePayload} />
                ) : (
                  <Typography
                    style={{
                      padding: "10px",
                      textAlign: "center",
                      color: "#FF1111",
                    }}
                  >
                    No Response Payload
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                {" "}
              </Grid>
              <Grid item xs={12}>
                {!!details &&
                !!details.ErrorMessage &&
                details.ErrorMessage !== "N/A" ? (
                  <PrettyPayload value={details?.ErrorMessage} />
                ) : null}
              </Grid>
            </Grid>
          ) : (
            <Typography
              style={{
                padding: "12px",
                textAlign: "center",
                color: "#FF1111",
              }}
            >
              No Details Found
            </Typography>
          )}
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

const S = {
  DetailsHeader: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: stretch;
    justify-content: stretch;
  `,
  DetailsHeaderItem: styled.div`
    place-self: center;
    font-weight: bold;
  `,
};
